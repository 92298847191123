// axios
import axios from 'axios'
import store from "../state/store"

const instance = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  config => {
    // Do something before request is sent
    const sessionData = localStorage.getItem('session') || sessionStorage.getItem('session')
    const parsedData = JSON.parse(sessionData)
    const accessToken = parsedData?.access

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)


instance.interceptors.response.use(response => {
  return response;
}, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
    try {
      const sessionData = localStorage.getItem('session') || sessionStorage.getItem('session')
      const parsedData = JSON.parse(sessionData)
      const refresh =  parsedData?.refresh // Retrieve the stored refresh token.
      // Make a request to your auth server to refresh the token.
      const response = await axios.post(store.getters['subdom/subdomData']() ? `https://${store.getters['subdom/subdomData']()}.${process.env.VUE_APP_API_DOMAIN}api/v1/auth/token/refresh/` : `${process.env.VUE_APP_API_URL}v1/auth/token/refresh/`,  {
        refresh,
      });
      const { access: newAccessToken, refresh: newRefreshToken } = response.data;
      // Store the new access and refresh tokens.
      let newData = JSON.parse(sessionData)
      newData.access = newAccessToken
      newData.refresh = newRefreshToken
      store.dispatch('session/setSessionData', newData)
      // Update the authorization header with the new access token.
      instance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
      return instance(originalRequest); // Retry the original request with the new access token.
    } catch (refreshError) {
      // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
      console.error('Token refresh failed:', refreshError);
      sessionStorage.removeItem('session')
      localStorage.removeItem('session')

      window.location.replace('login')
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
});

if(store.getters['subdom/subdomData']()) {
  instance.defaults.baseURL = `https://${store.getters['subdom/subdomData']()}.${process.env.VUE_APP_API_DOMAIN}api/`
}

//function
const getQueryPagination = (form) => {
  const params = [];

  if (form?.page && form?.page_size) {
    params.push(`page=${form.page}`, `page_size=${form.page_size}`);
  }

  if (form?.search) {
    params.push(`search=${form.search}`);
  }

  return params.length > 0 ? `?${params.join('&')}` : '?';
};



//logIn
instance.login = (form) => {
  return instance.post(`v1/auth/login/`, form)
    .then(response => response.data)
}

instance.autoLogin = (form) => {
  return instance.get(`v1/auth/auto-login/?user=${form.user}&center=${form.center}&token=${form.token}`)
    .then(response => response.data)
}

//register
instance.register = (form) => {
  return instance.post(`v1/auth/register/`, form)
    .then(response => response.data)
}

//landing
instance.sendContactMessage = (form) => {
  return instance.post(`landing/contact-with-arbitt/`, form)
    .then(response => response.data)
}

instance.getDataNavbar = () => {
  return instance.get("/v1/navbar/")
  .then(response => response.data)
}

//Activate account
instance.activateAccount = (form) => {
  return instance.get(`v1/auth/activate-account/?user=${form.user}&token=${form.token}`)
    .then(response => response.data)
}

//CENTER EPs
instance.createMediationCenter = (form) => {
  return instance.post(`v1/centers_create/`, form)
    .then(response => response.data)
}

//Forms application
instance.getForm = () => {
  return instance.get(`/v1/template_forms/default`)
  .then(response => response.data)
}

//Mediation Forms

instance.postMediationForm = (form, isMultipart = false) => {
  const headers = isMultipart
    ? { 'Content-Type': 'multipart/form-data' }
    : { 'Content-Type': 'application/json' };

  return instance.post("/v1/mediation_forms/", form, { headers })
    .then(response => response.data);
}

instance.getMediationForms = (form) => {
  return instance.get(`/v1/mediation_forms/${form.id}`)
  .then(response => response.data)
}

instance.putMediationForms = (form, isMultipart = false,id) => {

  const headers = isMultipart
    ? { 'Content-Type': 'multipart/form-data' }
    : { 'Content-Type': 'application/json' };

  return instance.put(`/v1/mediation_forms/${id}/`, form, { headers })
  .then(response => response.data)
}

instance.putUpdateStatus = (form) => {
  return instance.put(`/v1/mediation_forms/${form.id}/update-status/`, form)
  .then(response => response.data)
}

instance.deleteChunksFile = (form) => {
  let path = `/v1/mediation_forms/${form.id}/deleted-file/?file_id=${form.file_id}` ;

  if(form.path){
    path = form.path + `?file_id=${form.file_id}`;
  }

  if(form.hash){
    path = `/v1/external/applications/edit/file/?hash=${form.hash}&file_id=${form.file_id}`
  }
  return instance.delete(path)
  .then(response => response.data)
}

instance.postChunksFile = (form,id,hash,url_ep) => {
  const headers = { 'Content-Type': 'multipart/form-data' }
  let path = url_ep ? url_ep : `/v1/mediation_forms/${id}/add-file/`;
  if(hash){
    path = `/v1/external/applications/edit/file/?hash=${hash}`;
  }
  return instance.post(path, form, {headers})
  .then(response => response.data)
}

//Mediation Forms external

instance.getMediationFormsHash = (form) => {
  return instance.get(`/v1/external/applications/edit/?hash=${form.hash}`)
  .then(response => response.data)
}

instance.patchMediationFormsHash = (form) => {
  return instance.patch(`/v1/external/applications/edit/?hash=${form.hash}`, form)
  .then(response => response.data)
}

instance.putMediationFormsHash = (form) => {
  return instance.put(`/v1/external/applications/edit/?hash=${form.hash}`, form)
  .then(response => response.data)
}

instance.deleteMediationFormsHash = (form) => {
  return instance.delete(`/v1/external/applications/edit/?hash=${form.hash}`)
  .then(response => response.data)
}

//External new application
instance.getExternalForm = () => {
  return instance.get(`/v1/external/template/default/`)
  .then(response =>response.data)
}

instance.postExternalForm= (form) => {
  return instance.post("/v1/external/applications/create/", form)
  .then(response => response.data)
}

//External new consult
instance.postExternalConsult = (form) => {
  return instance.post("/v1/external/consult/create/", form)
  .then(response => response)
}


//Solicitudes EPS

instance.getRequestsList = (form) => {

  let query = getQueryPagination(form)

  if (form?.status !== null && form?.status >= 0) query +=
    query.length >1?
      `&status=${form.status}` : `status=${form.status}`

    return instance.get(`v1/mediation_forms/${query}`)
    .then(response => response.data)
}

// Consults EPs
instance.ConsultationDetail = (id) => {
  return instance.get(`/v1/consult/${id}/`)
  .then(response => response.data)
}

instance.patchConsultation = (form) => {
  return instance.patch(`/v1/consult/${form.id}/`, form)
  .then(response => response.data)
}

instance.getConsultationsList = (form) => {
  let query = getQueryPagination(form)

  if (form?.reviewed !== "null" && form?.reviewed) {
      query += query.length > 1 ? `&reviewed=${form.reviewed}` : `reviewed=${form.reviewed}`
    }

  return instance.get(`v1/consult/${query}`)
  .then(response => response.data)
}


// Clients EPs
instance.getClientDetail = (form) => {
  return instance.get(`/v1/clients/${form.id}/`)
  .then(response => response.data)
}

instance.patchClient = (form) => {
  return instance.patch(`/v1/clients/${form.id}/`, form)
  .then(response => response.data)
}

instance.getClientsList = (form) => {
  let query = getQueryPagination(form)
  return instance.get(`v1/clients/${query}`)
  .then(response => response.data)
}

instance.postClient = (form) => {
  return instance.post("/v1/clients/", form)
  .then(response => response.data)
}

instance.putAppendClient = (form) => {
  return instance.put(`/v1/mediation_forms/${form.id}/append-client/`, form)
  .then(response => response.data)
}

instance.deleteClient = (form) => {
  return instance.delete(`/v1/clients/${form.id}/`)
  .then(response => response.data)
}

// Sessions EPs
instance.getSessionDetail = (form) => {
  return instance.get(`/v1/sessions/${form.id}/`)
  .then(response => response.data)
}

instance.patchSession = (form) => {
  return instance.patch(`/v1/sessions/${form.id}/`, form)
  .then(response => response.data)
}

instance.getSessionList = (form) => {
  let query = getQueryPagination(form)
  return instance.get(`v1/sessions/${query}`)
  .then(response => response.data)
}

instance.postSession = (form) => {
  return instance.post("/v1/sessions/", form)
  .then(response => response.data)
}

instance.postSessionWithProceeding = (form,id) => {
  return instance.post(`/v1/proceeding/${id}/add-session/`, form)
  .then(response => response.data)
}

instance.getSessionWithProceeding = (form) => {
  return instance.get(`/v1/proceeding/${form.id}/session-list/${form.query}`)
  .then(response => response.data)
}


// BEGIN: Proceeding EPs
instance.getProceedingList = (form) => {

  let query = getQueryPagination(form)

  if (form?.type || form?.type === 0) {
    query += query.length > 1 ? `&type=${form.type}` : `type=${form.type}`
  }

  if (form?.status || form?.status === 0) {
    query += query.length > 1 ? `&status=${form.status}` : `status=${form.status}`
  }

  return instance.get(`v1/proceeding/${query}`)
  .then(response => response.data)
}

instance.getProceedingForm = (form) => {
  let query = '?'
  if (form?.pk ) query +=
  query.length >1? `&pk=${form.pk}` : `pk=${form.pk}`

  return instance.get(`/v1/proceeding/intervener-form/${query}`)
  .then(response => response.data)
}

instance.getProfessionalList = (form) => {
  let query = getQueryPagination(form)
  return instance.get(`v1/proceeding/add-professional-list/${query}`)
  .then(response => response.data)
}

instance.getProfessionalListWithProceeding = (form) => {
  return instance.get(`v1/proceeding/${form.id}/professional-list`)
  .then(response => response.data)
}

instance.getProceedingDetail = (form) => {
  return instance.get(`/v1/proceeding/${form.id}/dashboard`)
  .then(response => response.data)
}

instance.createProceeding = (formData) => {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return instance.post("/v1/proceeding/", formData,{headers})
  .then(response => response)
}

instance.editProceeding = (formData, id) => {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return instance.put(`/v1/proceeding/${id}/`, formData,{headers})
  .then(response => response)
}

instance.getDataWithProceeding = (form) => {
  let query = getQueryPagination(form)
  return instance.get(`v1/proceeding/${form.id}/${form.path}/${query}`)
  .then(response => response.data)
}

instance.getProceedingEditData = (form) => {
  return instance.get(`/v1/proceeding/${form.id}/`)
  .then(response => response.data)
}


//  - BEGIN Procedding - invoices
instance.getInvoicesFromProceeding = (form) => {
  let query = getQueryPagination(form)

  return instance.get(`v1/proceeding/${form.proceeding_id}/invoice-list/${query}`)
  .then(response => response.data)
}

instance.createInvoiceFromProceeding = (form, proceeding_id) => {
  return instance.post(`v1/proceeding/${proceeding_id}/add-invoice/`, form)
  .then(response => response.data)
}

instance.updateInvoiceFromProceeding = (form, proceeding_id, invoice_id) => {
  return instance.put(`v1/proceeding/${proceeding_id}/${invoice_id}`, form)
  .then(response => response.data)
}
//  - END Procedding - invoices
// END: Proceeding EPs

// Contacts EPs
instance.getContactDetail = (form) => {
  return instance.get(`/v1/contacts/${form.id}/`)
  .then(response => response.data)
}

instance.patchContact = (form) => {
  return instance.patch(`/v1/contacts/${form.id}/`,form)
  .then(response => response.data)
}

instance.getContactList = (form) => {
  let query = getQueryPagination(form)
  return instance.get(`v1/contacts/${query}`)
  .then(response => response.data)
}

//user profile EPS
instance.getUserProfile = () => {
  return instance.get(`/v1/userprofile/`)
  .then(response => response.data)
}

instance.putUserProfile = (form) => {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return instance.put(`/v1/userprofile/`, form,{ headers })
  .then(response => response.data)
}

//center profile EPS
instance.getCenterProfile = () => {
  return instance.get(`/v1/centerprofile/`)
  .then(response => response.data)
}

instance.putCenterProfile = (form) => {
  const headers = { 'Content-Type': 'multipart/form-data' }
  return instance.put(`/v1/centerprofile/`, form, { headers })
  .then(response => response.data)
}

//billing EPS
instance.getBillingProfile = () => {
  return instance.get(`/v1/billing_data/`)
  .then(response => response.data)
}

instance.putBillingProfile = (form) => {
  return instance.put(`/v1/billing_data/`, form)
  .then(response => response.data)
}

instance.getSessionsList = (form) => {
  let query = getQueryPagination(form)
  return instance.get(`/v1/sessions/${query}`)
  .then(response => response.data)
}


instance.getFile = (form) => {
  return instance.get(`${form.url}`)
  .then(response => response.data)
}

//events
instance.postEvent = (form) => {
  return instance.post(`/v1/events/`, form)
  .then(response => response.data)
};

instance.getEventsList = (form) => {
  let query = ''
  if (form?.year && form?.month ) query += `?month=${form.month}&year=${form.year}`
  return instance.get(`/v1/events/${query}`)
  .then(response => response.data)
};

instance.getEventDetail = (form) => {
  return instance.get(`/v1/events/${form.id}/`)
  .then(response => response.data)
};

instance.putEvent = (form) => {
  return instance.put(`/v1/events/${form.id}/`,form)
  .then(response => response.data)
};

export default instance