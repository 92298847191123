export const state = {
  isShowingRightSidebar: false,
  sidebarSize: '400px'
};

export const mutations = {
  TOGGLE_RIGHT_SIDEBAR(state) {
    state.isShowingRightSidebar = !state.isShowingRightSidebar
  },
  OPEN_RIGHT_SIDEBAR(state) {
    state.isShowingRightSidebar = true
  },
  CLOSE_RIGHT_SIDEBAR(state) {
    state.isShowingRightSidebar = false
  },
  SET_SIDEBAR_SIZE(state, size) {
    state.sidebarSize = `${size}px`
  },

}

export const actions = {
  toggleRightSidebar({ commit }) {
    commit('TOGGLE_RIGHT_SIDEBAR')
  },
  openRightSidebar({ commit }) {
    commit('OPEN_RIGHT_SIDEBAR')
  },
  closeRightSidebar({ commit }) {
    commit('CLOSE_RIGHT_SIDEBAR')
  },
  setSidebarSize({ commit }, size) {
    commit('SET_SIDEBAR_SIZE', size)
  }
}

export const getters = {
  isShowingRightSidebar: (state) => () => { return state.isShowingRightSidebar },
  getSidebarSize: (state) => () => { return state.sidebarSize },
}