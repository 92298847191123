export const state = {
  navbar_title: 'Inicio',
};


export const mutations = {
  CHANGE_NAVBAR_TITLE(state, title) {
    state.navbar_title = title;
  }
};

export const actions = {
  changeNavbarTitle({ commit },  title) {
    commit('CHANGE_NAVBAR_TITLE', title);
  }
};

export const getters = {
  getNavbarTitle: (state) => () => { return state.navbar_title },
};