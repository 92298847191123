export const state = {
  subdom: window.location.host.split('.')[0] === 'arbitt' ? false : window.location.host.split('.')[0]
};

export const mutations = {
  SET_SUBDOM_DATA(state, data) {
    state.subdom = data

    localStorage.setItem('subdom', JSON.stringify(data))
    sessionStorage.setItem('subdom', JSON.stringify(data))
  },

  CLEAR_SUBDOM_DATA(state) {
    state.subdom = {}

    sessionStorage.removeItem('subdom')
    localStorage.removeItem('subdom')
  },
}

export const actions = {
  setSubdomData({ commit }, subdom_data) {
    commit('SET_SUBDOM_DATA', subdom_data)
  }
}

export const getters = {
  subdomData: (state) => () =>{ return state.subdom },
}