<script setup>

// libraries
import {onMounted,provide,ref} from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@unhead/vue'
//components

// variables
import {
  METAS_PER_PAGE,
} from "@/utils/metas.js";
const store = useStore();
const subdom = ref (store.getters['subdom/subdomData']());
// functions

// lifecycle hooks

onMounted(() => {
  document.body.setAttribute('data-pc-sidebar-theme', "dark");
    const sessionData = localStorage.getItem('session') || sessionStorage.getItem('session')
    if (sessionData) {
      const sessionDataParsed = JSON.parse(sessionData)
      store.dispatch('session/setSessionData', sessionDataParsed)
    }
});

useHead({
  title: 'Arbitt',
  meta : METAS_PER_PAGE(subdom.value),
});


// watchers
provide('subdom',
  store.getters['subdom/subdomData']()
);
</script>


<template>
  <router-view v-slot="{ Component }">
    <Transition name="fade-slow" mode="out-in">
      <div>
        <component :is="Component" />
      </div>
    </Transition>
  </router-view>
</template>
