const ARBITT = {
  description: 'Gestiona tu centro desde cualquier lugar con nuestro software en la nube. ¡Todo lo que necesitas, al alcance de tu mano, en cualquier momento!',
  title: 'Arbitt',
  url: "https://arbitt.io",
  sitename: "Arbitt",
  image: process.env.VUE_APP_API_DOMAIN + 'public/images/arbitt-logo.png'
};
export const METAS_PER_PAGE = (domain) => {
  if(!domain) return ARBITT_METAS;
};

const ARBITT_METAS = [
  { name: 'description', content: ARBITT.description},
  { property: 'og:title', content: ARBITT.title },
  { property: 'og:description', content: ARBITT.description },
  { property: 'og:image', content: ARBITT.image },
  { property: 'og:url', content: ARBITT.url },
  { property: 'og:type', content: 'website' },
  { property: 'twitter:card', content: 'summary_large_image' },
  { property: 'twitter:site', content: ARBITT.title },
];


